import React from "react";
import { Box, Grid, List, ListItem, Typography, Link } from "@mui/material";

export default function Main() {
  return (
    <Box sx={{ py: 5, px: "10vw", bgcolor: "rgba(0,0,0,0.3);" }}>
      <Typography variant="h5" gutterBottom>
        Quick Links
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <List dense>
            <Qlink name="Home" to="/" />
            <Qlink name="About" to="/more/aboutMe" />
            <Qlink name="Projects" to="/more/projects" />
            <Qlink name="Contact" to="/more/contactMe" />
          </List>
        </Grid>
        <Grid item xs>
          <List dense>
            <Qlink name="2048 Game" to="/2048Game" />
            <Qlink name="Template" to="/template" />
          </List>
        </Grid>
      </Grid>
    </Box>
  );
}

function Qlink({ name, to }) {
  return (
    <ListItem>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        sx={{ my: 1 }}
        component={Link}
        href={to}
        gutterBottom
      >
        {name}
      </Typography>
    </ListItem>
  );
}
