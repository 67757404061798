import React from "react";
import { Helmet } from "react-helmet";

import {
  Box,
  CardActionArea,
  Collapse,
  Divider,
  Grow,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";

import BackgroundImage from "../assets/Background1.svg";
import CircuitBG from "../assets/circuit-board.svg";
import Copyright from "./components/Copyright";
// import ThemeSlider from "./components/ThemeSlider";
import QuickNav from "./QuickNav";

import { useInView } from "react-intersection-observer";

import "./SlidingMainScreen.css";
import Eye from "./components/Eye";
import { disableScroll, enableScroll } from "./PreventScroll";

export default function Main() {
  const fullScreenBox = {
    height: "100%",
    maxWidth: "100vw",
    m: 0,
    p: 0,
  };

  const fullBackground = {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100dvh",
    width: "100vw",
    position: "fixed",
    zIndex: -1000,
  };

  const [open, setOpen] = React.useState(false);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView) {
      setTimeout(() => setOpen(true), 500);
    } else {
      setOpen(false);
    }
  }, [inView]);

  return (
    <Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adam Desa's Portfolio</title>
        <link rel="canonical" href="https://adamdesa.com/" />
      </Helmet>
      <Box sx={fullBackground} />
      <Box
        sx={{
          ...fullScreenBox,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <MainContent />
          <SecondaryContent />
          <Divider />
          <Box sx={{ width: 0, m: 0 }} ref={ref} />
          <Collapse in={open} timeout={700}>
            <QuickNav />
          </Collapse>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
}

function MainContent() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ pb: 2 }}
      >
        <Box
          sx={{
            height: "150px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={"/logo.svg"}
            style={{
              filter: "drop-shadow(2px 2px 6px #000)",
            }}
            alt="logo"
          />
        </Box>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <Typography
            variant="h1"
            align="center"
            sx={{
              fontFamily: "system-ui",
              fontWeight: "bold",
              fontSize: "3.75rem",
              textShadow: (theme) =>
                `4px 4px ${theme.palette.mode === "light" ? "10px" : "0px"} ${
                  theme.palette.primary.main
                }`,
            }}
          >
            Adam Desa's Portfolio
          </Typography>
          <Typography
            variant="h5"
            sx={{
              textShadow: (theme) =>
                `1px 1px ${theme.palette.mode === "light" ? "3px" : "2px"} ${
                  theme.palette.secondary.main
                }`,
            }}
          >
            Welcome to Innovation
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

function SecondaryContent() {
  const [open, setOpen] = React.useState(false);

  const openTime = 500;

  const [ref, inView] = useInView({
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView) setOpen(true);
  }, [inView]);

  const scrollRef = React.useRef(null);

  const onCollapseEnd = () => {
    disableScroll();
    setTimeout(
      () =>
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        }),
      openTime
    );
    setTimeout(() => enableScroll(), openTime + 1000);
  };

  return (
    <Paper sx={{ width: "100%" }}>
      <Box sx={{ backgroundImage: `url(${CircuitBG})` }}>
        <Divider sx={{ my: 7 }} />

        <Box sx={{ width: 0, m: 0 }} ref={ref} />
        <Collapse in={open} timeout={openTime} addEndListener={onCollapseEnd}>
          <Box height={"100dvh"} ref={scrollRef} sx={{ py: 2 }}>
            <AnimatedWelcomeScreen />
          </Box>
        </Collapse>
      </Box>
    </Paper>
  );
}

function AnimatedWelcomeScreen() {
  const color = "#aa41ff";

  const [clickMe, setClickMe] = React.useState(false);

  const [eyeRef, inView] = useInView({
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView) {
      setTimeout(() => {
        setClickMe(true);
      }, 4000);
    }
  }, [inView]);

  return (
    <Stack
      spacing={4}
      justifyContent="center"
      alignItems="center"
      sx={{ height: "inherit" }}
      ref={eyeRef}
    >
      <AnimatedItem>
        <Stack
          spacing={5}
          direction={{ xs: "column", sm: "column" }}
          justifyContent="center"
          alignItems="center"
        >
          <BounceText color={color} />
        </Stack>
      </AnimatedItem>
      <AnimatedItem position={5}>
        <CardActionArea
          component={Link}
          to="/more/aboutMe"
          sx={{
            p: 2,
            borderRadius: 2,
            pt: 5,
          }}
        >
          <Eye color={color} />
          <Typography
            variant="h5"
            color={color}
            sx={{
              ...(clickMe && {
                transition: "all 0.35s ease-in-out",
                animation: `shakeme 6s infinite`,
              }),
            }}
          >
            See What I've Got!
          </Typography>
        </CardActionArea>
      </AnimatedItem>
    </Stack>
  );
}

function BounceText({ color }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isComputer = useMediaQuery("(min-width:1024px)");
  const fontSize = isMobile ? "2rem" : isComputer ? "6rem" : "5rem";

  return (
    <Box sx={{ maxWidth: "800px", mx: "auto", textAlign: "center" }}>
      <Typography
        variant={"h2"}
        display="inline"
        sx={{ fontSize: fontSize, fontWeight: isMobile && "bold" }}
      >
        Hi, I'm{" "}
      </Typography>
      <Typography
        variant={"h2"}
        color={color}
        display="inline"
        sx={{ fontSize: fontSize, fontWeight: isMobile && "bold" }}
      >
        A
      </Typography>
      <Typography
        variant={"h2"}
        display="inline"
        sx={{ fontSize: fontSize, fontWeight: isMobile && "bold" }}
      >
        dam{" "}
      </Typography>
      <Typography
        variant={"h2"}
        color={color}
        display="inline"
        sx={{ fontSize: fontSize, fontWeight: isMobile && "bold" }}
      >
        D
      </Typography>
      <Typography
        variant={"h2"}
        display="inline"
        sx={{ fontSize: fontSize, fontWeight: isMobile && "bold" }}
      >
        esa, a fullstack developer.
      </Typography>
    </Box>
  );
}

function AnimatedItem({ children, position = 0 }) {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  return (
    <Box
      sx={{
        textShadow: (theme) =>
          theme.palette.mode === "dark"
            ? `5px 5px 2px #242729`
            : `0px 0px 5px #fff`,
      }}
    >
      <Box sx={{ width: 0, m: 0 }} ref={ref} />
      <Grow
        in={inView}
        timeout={{ appear: 500 * position, enter: 1000, exit: 100 }}
      >
        <Box>{children}</Box>
      </Grow>
    </Box>
  );
}
