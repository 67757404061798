import React from "react";
import { Box } from "@mui/system";

import Copyright from "../common/components/Copyright";
import MenuePage from "../common/components/MenuePage";
import {
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Helmet from "react-helmet";

import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import KeyIcon from "@mui/icons-material/Key";
import {
  DeveloperMode,
  Devices,
  Email,
  LightMode,
  NotificationAdd,
  Palette,
  Password,
} from "@mui/icons-material";

import LoginPhoto from "../assets/templatePhotos/Login.png";
import AccessCPhoto from "../assets/templatePhotos/AccessControl.png";
import RolesPhoto from "../assets/templatePhotos/Roles.png";
import ManageUserPhoto from "../assets/templatePhotos/ManageUser.png";
import GoHome from "../common/components/GoHome";

export default function Main() {
  return (
    <>
      <Helmet>
        <title>Adam Desa's Portfolio | Flask + React Template</title>
        <meta name="description" content="Flask + React Template" />
      </Helmet>
      <GoHome />
      <MenuePage title="Flask + React Template" subtitles={[]} id="top">
        <Stack
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h4" align="center">
            Why create a template?
          </Typography>
          <Typography variant="body1">
            I'm sure it has been done but I couldn't find any other templates
            using React and Flask so I made one myself.
          </Typography>
          <Divider />
          <Typography variant="h4">Features</Typography>
          <Typography variant="body1">
            The following features are included in this template and can be
            managed using the configuration file.
          </Typography>
          <FeatureList />
          <Paper sx={{ p: 2, backgroundColor: "rgba(0,0,0,0.2)" }}>
            <Typography variant="body1" gutterBottom>
              Styled using Material-UI.
            </Typography>
            <Typography variant="body1" gutterBottom>
              The template is fully configurable, via the configuration files in
              both the Flask app and React app.
            </Typography>
            <Typography variant="body1" gutterBottom>
              It has been configured to be used with{" "}
              <Link
                href="https://www.heroku.com/"
                target="_blank"
                color="secondary"
              >
                heroku
              </Link>{" "}
              for production and only takes a few minutes to setup.
            </Typography>
            <Typography variant="body1" gutterBottom>
              To gain access to the code for this template please{" "}
              <Link href="/more/contactMe" target="_blank" color="secondary">
                contact me
              </Link>
              .
            </Typography>
          </Paper>
        </Stack>
        <Divider sx={{ my: 5 }} />
        <Typography variant="h4" align="center" gutterBottom>
          Admin Pages
        </Typography>
        <Stack
          alignContent="center"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <PhotoText
            title="Admin Dashboard"
            text="view recently created accounts."
            photo={LoginPhoto}
          />
          <PhotoText
            title="Access Control"
            text="Manage pages and which roles can access them."
            photo={AccessCPhoto}
          />
          <PhotoText
            title="Roles"
            text="Create, delete and edit roles. See which roles have access to different pages."
            photo={RolesPhoto}
          />
          <PhotoText
            title="Manage Users"
            text="Change user account details and access."
            photo={ManageUserPhoto}
          />
        </Stack>
      </MenuePage>
      <Box
        sx={{ position: "relative", bottom: 0, left: 0, right: 0, pt: 2, p: 1 }}
      >
        <Copyright />
      </Box>
    </>
  );
}

function PhotoText({ photo, title, text }) {
  return (
    <Paper variant="outlined" sx={{ p: 2, width: "fit-content", mx: "auto" }}>
      <Stack
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Box>
          <Typography variant="h4" align="center">
            {title}
          </Typography>
          <Typography variant="body1" align="center">
            {text}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: "50vw" }}>
          <img src={photo} alt={title} width="100%" />
        </Box>
      </Stack>
    </Paper>
  );
}

function FeatureList() {
  return (
    <List>
      <ListItem>
        <ListItemIcon>
          <SupervisorAccountIcon />
        </ListItemIcon>
        User Managment
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <KeyIcon />
        </ListItemIcon>
        Access Control
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Email />
        </ListItemIcon>
        Email Verification
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Password />
        </ListItemIcon>
        Password Reset
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Devices />
        </ListItemIcon>
        Device Managment
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <DeveloperMode />
        </ListItemIcon>
        Heroku Deployment
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <NotificationAdd />
        </ListItemIcon>
        Notifications
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <LightMode />
        </ListItemIcon>
        Light/Dark Mode
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <Palette />
        </ListItemIcon>
        Customizable Colour Theme
      </ListItem>
    </List>
  );
}
