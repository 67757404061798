import React from "react";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import LightenDarkenColor from "./components/ColourBrigthness";

import darkScrollbar from "@mui/material/darkScrollbar";

export const CustomThemeContext = React.createContext({
  currentTheme: "dark",
  setTheme: null,
});

const CustomThemeProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;

  // Read current theme from localStorage default is dark, to change use light
  const currentTheme = localStorage.getItem("appTheme") || "dark";
  // Theme specific notifications

  // State to hold the selected theme name
  const [themeName, _setThemeName] = React.useState(currentTheme);

  const defaultM = "#691FAB";
  const defaultS = "#00b0ff";

  const [MainColor, setMainColor] = React.useState(defaultM);
  const [SecondaryColor, setSecondaryColor] = React.useState(defaultS);

  React.useEffect(() => {
    if (themeName === "dark") {
      setMainColor(LightenDarkenColor(defaultM, 20));
    } else {
      setMainColor(defaultM);
      setSecondaryColor(defaultS);
    }
  }, [themeName, MainColor, SecondaryColor]);

  // Retrieve the theme object by theme name
  const theme = createTheme({
    palette: {
      mode: themeName,
      primary: {
        main: MainColor,
      },
      secondary: {
        main: SecondaryColor,
      },
      background: {
        ...(themeName === "dark"
          ? {
              default: "#242729",
              paper: "#323638",
            }
          : {
              default: "#fafafa",
              paper: "#fff",
            }),
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: themeName === "dark" ? darkScrollbar() : null,
        },
      },
    },
  });

  React.useEffect(() => {
    const root = document.documentElement.style;
    root.setProperty("--themec", theme.palette.background.paper);
    root.setProperty("--default", theme.palette.background.default);
    root.setProperty("--main", theme.palette.secondary.dark);
    root.setProperty("--maincolor", theme.palette.primary.main);
    root.setProperty("--secondarycolor", theme.palette.secondary.main);

    root.setProperty("--success", theme.palette.success.dark);
    root.setProperty("--info", theme.palette.info.dark);
    root.setProperty("--warning", theme.palette.warning.dark);
    root.setProperty("--error", theme.palette.error.dark);
    root.setProperty("--contrastText", theme.palette.success.contrastText);
  }, [themeName, theme]);

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeName = (name) => {
    localStorage.setItem("appTheme", name);
    _setThemeName(name);
  };

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
