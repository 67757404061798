import React from "react";

import {
  Tab,
  Tabs,
  Box,
  Slide,
  Dialog,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";

import { useTheme } from "@emotion/react";
import Copyright from "./components/Copyright";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";

export default function PopUp() {
  const theme = useTheme();

  const routes = [
    { label: "Home", path: "/", icon: <HomeOutlinedIcon /> },
    { label: "About", path: "aboutMe", icon: <InfoOutlinedIcon /> },
    { label: "Projects", path: "projects", icon: <FolderCopyOutlinedIcon /> },
    { label: "Contact", path: "contactMe", icon: <ContactPageOutlinedIcon /> },
  ];

  // Get the current pathname and find it in the array to set the correct tab as selected
  const location = useLocation();
  const index = routes.findIndex((object) => {
    return (
      `/more/${object.path}` === location.pathname ||
      `/more/${object.path}/` === location.pathname
    );
  });
  const [tabVal, setTabVal] = React.useState(index || 0);

  const handleChange = (event, newValue) => {
    setTabVal(newValue);
  };

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen
      open={true}
      TransitionComponent={Transition}
      PaperProps={{ elevation: 0 }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.default,
          position: "fixed",
          left: 0,
          right: 0,
          zIndex: 1000,
          ...(isMobile ? { bottom: 0 } : { top: 0 }),
        }}
      >
        <Tabs
          value={tabVal}
          onChange={handleChange}
          sx={{ p: 1 }}
          textColor="inherit"
          centered={isMobile}
        >
          {isMobile
            ? routes.map((route, index) => (
                <Tooltip title={route.label} key={index} arrow>
                  <Tab
                    icon={route.icon}
                    iconPosition={isMobile ? "top" : "start"}
                    component={Link}
                    to={route.path}
                  />
                </Tooltip>
              ))
            : routes.map((route, index) => (
                <Tab
                  key={index}
                  icon={route.icon}
                  iconPosition={isMobile ? "top" : "start"}
                  label={route.label}
                  component={Link}
                  to={route.path}
                />
              ))}
          {}
        </Tabs>
      </Box>

      <Outlet />

      <Box
        sx={{
          position: "relative",
          bottom: 0,
          left: 0,
          right: 0,
          pt: 2,
          p: 1,
          ...(isMobile && { pb: 8 }),
        }}
      >
        <Copyright />
      </Box>
    </Dialog>
  );
}
