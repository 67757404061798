import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function MenuePage({ title, subtitles, children }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const transperancyAmount = 0.5;

  const calcVal = Math.round(transperancyAmount * 255).toString(16);
  const transperancy = (calcVal.length === 1 ? "0" : "") + calcVal;

  const backgroundStyle = {
    backgroundColor: theme.palette.background.default,
    background: `linear-gradient(45deg , ${theme.palette.primary.main}${transperancy} 0%, ${theme.palette.secondary.main}${transperancy} 100%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    height: isMobile ? "fit-content" : null,
  };

  return (
    <Box>
      <Box
        sx={{
          mt: isMobile ? 2 : 8,
          p: 2,
          pt: 5,
          pl: 5,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontFamily: "system-ui",
            fontWeight: "bold",
            fontSize: "3.5rem",
            mb: 2,
          }}
        >
          {title}
        </Typography>
        {subtitles.map((subtitle, index) => (
          <Typography
            key={index}
            variant="body1"
            sx={{ pl: 0.3, fontSize: "1.1rem" }}
            gutterBottom
          >
            {subtitle}
          </Typography>
        ))}
      </Box>
      <Divider sx={{ mt: 5, mb: 0 }} />
      <Box sx={{ ...backgroundStyle, pt: 2, pb: 10, mb: 3, px: 5 }}>
        {children}
      </Box>
    </Box>
  );
}
