import flaskReact from "../assets/ProgramImages/flaskReact.png";
import img2048 from "../assets/ProgramImages/2048.png";
import goKenya from "../assets/ProgramImages/goKenya.png";
import skulldorom from "../assets/ProgramImages/skulldorom_com.png";
import hobbity from "../assets/ProgramImages/hobbity.png";
import Arithmetic from "../assets/ProgramImages/Arithmetic.png";
// import covid from "../assets/ProgramImages/covid.png";
// import rsa from "../assets/ProgramImages/rsa.png";
// import webenc5 from "../assets/ProgramImages/webenc5.png";
import encrypt from "../assets/ProgramImages/encrypt.png";
import crystalisis from "../assets/ProgramImages/crystalisis.png";
import batterySD from "../assets/ProgramImages/BatteryIconSD.png";

// To add a new program to the list

/* {
    name: "",
    description: "",
    url: "/",
    newTab: true,
  },
*/
const programList = [
  {
    name: "Flask + React Template",
    description:
      "A custom template using Flask and React with features such as email verification, password management, user management and access control built in.",
    url: "/template",
    img: flaskReact,
    newTab: true,
    technologies: [
      "Flask",
      "React",
      "Material-UI",
      "Heroku",
      "PostgreSQL",
      "HTML",
      "CSS",
      "JavaScript",
      "Python",
    ],
  },
  {
    name: "Bluetooth Battery Monitor Stream Deck Plugin",
    description:
      "A Stream Deck plugin that monitors the battery of your bluetooth devices.",
    url: "https://github.com/Skulldorom/Bluetooth-Battery-Streamdeck",
    img: batterySD,
    newTab: true,
    technologies: ["TypeScript", "JavaScript", "Stream Deck SDK", "Windows"],
  },
  {
    name: "2048",
    description: "Clone of the game 2048, proof of concept.",
    url: "/2048Game",
    img: img2048,
    newTab: true,
    technologies: ["React", "Material-UI", "HTML", "CSS", "JavaScript"],
  },
  {
    name: "Arithmetic Game",
    description: "A simple game to test your arithmetic skills",
    url: "https://skulldorom.com/Arithmetic",
    img: Arithmetic,
    newTab: true,
    technologies: ["React", "Material-UI", "HTML", "CSS", "JavaScript"],
  },
  {
    name: "Go Kenya",
    description:
      "Final Project at USIU, this was a joint project with one other student, Go kenya has both a web application and mobile application prototype. The link is for a youtube video more details in the video description.",
    links: [
      {
        name: "Github",
        url: "https://github.com/Skulldorom/go-kenya-v2",
      },
    ],
    url: "https://youtu.be/pHpOxBQfC0E",
    img: goKenya,
    newTab: true,
    technologies: ["React", "Material-UI", "HTML", "CSS", "JavaScript"],
  },
  {
    name: "Hobbity",
    description:
      "This was a group project, using express, username: skull password: test",
    links: [
      {
        name: "Github",
        url: "https://github.com/bijuice/Advanced-Web-Design-Project",
      },
    ],
    url: "https://hobbity.onrender.com/",
    img: hobbity,
    newTab: true,
    technologies: ["express", "HTML", "CSS", "JavaScript", "Heroku"],
  },
  {
    name: "Skulldorom.com",
    description: "A fun side project I made for game related stuff.",
    url: "https://skulldorom.com",
    img: skulldorom,
    newTab: true,
    technologies: ["React", "Material-UI", "HTML", "CSS", "JavaScript"],
  },
  // {
  //   name: "Covid Tracker",
  //   description:
  //     "A dashboard for covid cases for different regions, with a graph of cases over time. Data is pulled from 2 different APIs.",
  //   url: "https://sdrom.onrender.com/covid",
  //   img: covid,
  //   newTab: true,
  //   technologies: ["Flask", "Python", "Heroku", "HTML", "CSS", "JavaScript"],
  // },
  // {
  //   name: "RSA Messenger",
  //   description:
  //     "A method to encrypt and decrypt messages using RSA, proof of concept.",
  //   url: "https://sdrom.onrender.com/RSA",
  //   img: rsa,
  //   newTab: true,
  //   technologies: ["Flask", "Python", "Heroku", "HTML", "CSS", "JavaScript"],
  // },
  // {
  //   name: "Web Encrypter",
  //   description: "Version 5 of the encrypter application hosted online.",
  //   url: "https://sdrom.onrender.com/encrypt",
  //   img: webenc5,
  //   newTab: true,
  //   technologies: ["Flask", "Python", "Heroku", "HTML", "CSS", "JavaScript"],
  // },
  {
    name: "Enc_4.0",
    description: "VB.Net self made encrypting tool.",
    url: "https://github.com/Skulldorom/MyFiles/raw/main/Enc_4.0.zip?raw=true",
    img: encrypt,
    newTab: false,
    download: true,
    windows: true,
    technologies: ["Visual Studio", "Windows"],
  },
  {
    name: "Encrypter Pack",
    description: "VB.Net self made encrypting tool, verions 4-1.",
    url: "https://github.com/Skulldorom/MyFiles/raw/main/EncrypterPack.zip?raw=true",
    img: encrypt,
    newTab: false,
    download: true,
    windows: true,
    technologies: ["Visual Studio", "Windows"],
  },
  {
    name: "Crystalis Game",
    description:
      "A text-based decision game that I wrote for fun using VB.net, enjoy the witty humor and try and get all 5 endings! oh and before I forget good luck trying to find the 2 hidden easter eggs.",
    url: "https://github.com/Skulldorom/MyFiles/blob/main/Crytalisis.zip?raw=true",
    img: crystalisis,
    newTab: false,
    download: true,
    windows: true,
    technologies: ["Visual Studio", "Windows"],
  },
];

export default programList;
