import React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Card,
  Grid,
  CardContent,
  CardActions,
  Button,
  Paper,
  Link,
  Slide,
  Collapse,
  Chip,
  Stack,
  CardMedia,
  Skeleton,
  Box,
  Fade,
  Dialog,
  DialogContent,
  DialogContentText,
  Fab,
  useMediaQuery,
  Tooltip,
  SvgIcon,
} from "@mui/material";

import programList from "../data/programList";
import MenuePage from "../common/components/MenuePage";
import {
  BuildCircleOutlined,
  Download,
  InfoOutlined,
  OpenInNew,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import LightenDarkenColor from "../common/components/ColourBrigthness";
import skillList from "../data/skillList";

export default function Main() {
  const subList = [
    "Some web applications and other tools that I have made",
    "For most of these projects you can find the code in github",
    "In order from newest to oldest",
  ];

  const [programAnimate, setProgramAnimate] = React.useState([]),
    [startAnimate, setStartAnimate] = React.useState(false);

  React.useEffect(() => {
    const list = [];
    for (let i = 0; i < programList.length; i++) {
      list.push(false);
    }
    setProgramAnimate(list);

    setTimeout(() => {
      setStartAnimate(true);
    }, 700);
  }, []);

  React.useEffect(() => {
    if (startAnimate) {
      for (let i = 0; i < programList.length; i++) {
        setTimeout(() => {
          setProgramAnimate((prev) => {
            const newList = [...prev];
            newList[i] = true;
            return newList;
          });
        }, 100);
      }
    }
  }, [startAnimate]);

  return (
    <MenuePage title="Projects and Programs" subtitles={subList}>
      <Helmet>
        <title>Adam Desa's Portfolio | Projects</title>
        <meta
          name="description"
          content="Programs and Projects done by Adam Desa"
        />
      </Helmet>
      <Collapse in={startAnimate}>
        <Grid container spacing={3} sx={{ py: 5 }}>
          {programList.map((program, index) => (
            <Grid item xs={12} sm={6} md={4} xl={3} key={index}>
              <ProgramCard data={program} animate={programAnimate[index]} />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    </MenuePage>
  );
}

function ProgramCard({ data, animate }) {
  const [hover, setHover] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [imgLoading, setImgLoading] = React.useState(true);

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const ref = React.useRef(null);
  const [height, setHeight] = React.useState(180);

  React.useLayoutEffect(() => {
    const calc = (ref?.current?.offsetWidth / 16) * 9 || 180;
    setHeight(calc);
  }, []);

  const HoverText = () => (
    <Box
      sx={{
        display: imgLoading ? "none" : "block",
        position: "absolute",
        marginLeft: 2,
        marginTop: 2,
        maxWidth: { xs: "190px", md: "200px", lg: "600px", xl: "600px" },
      }}
    >
      <Fade in={true} timeout={1000}>
        <Typography
          variant="h5"
          color="white"
          sx={{
            fontWeight: "bold",
            textShadow: "1px 1px 5px black",
            maxWidth: "300px",
          }}
        >
          {data.name}
        </Typography>
      </Fade>
    </Box>
  );

  return (
    <>
      <Slide in={animate} direction="up">
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            transform: hover ? "scale(1.15) !important" : "scale(1)",
            zIndex: hover ? 1 : 0,
          }}
          raised={hover}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={handleOpen}
        >
          {imgLoading && (
            <Skeleton
              ref={ref}
              variant="rectangular"
              width="100%"
              height={height}
            />
          )}
          <CardMedia
            component="img"
            height="auto"
            width="100%"
            image={data.img}
            alt={data.name}
            sx={{ display: imgLoading ? "none" : "block" }}
            onLoad={handleImageLoad}
          />
          <HoverText />
          <Collapse in={hover}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {data.name}
              </Typography>
              <Stack
                spacing={2}
                direction={{ xs: "column", lg: "row" }}
                alignItems="flex-start"
                sx={{ mb: 1 }}
              >
                {data.windows && (
                  <Chip
                    color="primary"
                    icon={<WindowsLogo />}
                    label="Requires Windows"
                  />
                )}
              </Stack>
              <Typography variant="body1" color="text.secondary">
                {data.description}
              </Typography>
            </CardContent>
            <CardActions sx={{ p: 0 }}>
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                  p: 1,
                }}
              >
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    startIcon={<InfoOutlined />}
                    onClick={handleOpen}
                  >
                    More Info
                  </Button>
                  <Button
                    variant="contained"
                    component={Link}
                    href={data.url}
                    target={data.newTab ? "_blank" : ""}
                    startIcon={data.download ? <Download /> : <OpenInNew />}
                  >
                    {data.download ? "Download" : "Go to"}
                  </Button>
                </Stack>
              </Paper>
            </CardActions>
          </Collapse>
        </Card>
      </Slide>

      <ProgramDialog open={open} onClose={handleClose} data={data} />
    </>
  );
}

function ProgramDialog({ open, onClose, data }) {
  const theme = useTheme();
  const fColor =
    theme.palette.mode === "dark"
      ? LightenDarkenColor(theme.palette.background.paper, 30)
      : theme.palette.background.paper;

  const [imgLoading, setImgLoading] = React.useState(true);

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    let currentWidth = theme.breakpoints.values.md;
    if (lessThanSmall)
      currentWidth = document.documentElement.clientWidth * 0.84;
    setHeight((currentWidth / 16) * 9);
  }, [lessThanSmall, theme]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Fab
        onClick={onClose}
        size="small"
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          backgroundColor: "rgba(0,0,0,0.3)",
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.3)",
          },
        }}
      >
        <CloseIcon />
      </Fab>
      {imgLoading && (
        <Skeleton variant="rectangular" width="100%" height={height} />
      )}
      <CardMedia
        component="img"
        height={height}
        width="100%"
        image={data.img}
        alt={data.name}
        sx={{ display: imgLoading ? "none" : "block" }}
        onLoad={handleImageLoad}
      />
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: height,
          background: `linear-gradient(0deg, ${fColor}, transparent 50%)`,
        }}
      />
      <Box sx={{ pt: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ pl: 3 }}>
          {data.name}
          <Button
            variant="contained"
            component={Link}
            href={data.url}
            target={data.newTab ? "_blank" : ""}
            startIcon={data.download ? <Download /> : <OpenInNew />}
            sx={{ ml: 2 }}
          >
            {data.download ? "Download" : "Go to"}
          </Button>
        </Typography>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ display: "flex", alignItems: "center" }}
              >
                About <SvgIcon component={InfoOutlined} sx={{ ml: 1 }} />
              </Typography>
              <DialogContentText gutterBottom>
                {data.description}
              </DialogContentText>
              {data.links &&
                data.links.map((link) => (
                  <Link key={link.url} href={link.url} color="secondary">
                    {link.name}: {link.url}
                  </Link>
                ))}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ display: "flex", alignItems: "center" }}
              >
                Technologies Used
                <SvgIcon component={BuildCircleOutlined} sx={{ ml: 1 }} />
              </Typography>
              <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
                {data.technologies.map((tech) => (
                  <CustomTech key={tech} name={tech} />
                ))}
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

function CustomTech({ name }) {
  const [hover, setHover] = React.useState(false);

  if (name === "Windows") {
    return (
      <Box
        sx={{ p: 1 }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Tooltip title={name}>
          <svg
            viewBox="0 0 128 128"
            height="20"
            width="20"
            style={{
              filter: "drop-shadow(5px 5px 5px #222)",
              transform: hover && "scale(1.2)",
            }}
          >
            <path
              fill="#fff"
              d="M126 1.637l-67 9.834v49.831l67-.534zM1.647 66.709l.003 42.404 50.791 6.983-.04-49.057zm56.82.68l.094 49.465 67.376 9.509.016-58.863zM1.61 19.297l.047 42.383 50.791-.289-.023-49.016z"
            ></path>
          </svg>
        </Tooltip>
      </Box>
    );
  }

  const techs = skillList.reduce(
    (obj, skill) => Object.assign(obj, { [skill.name]: skill.icon }),
    {}
  );
  const icon = techs[name];

  return (
    <Box
      sx={{ p: 1 }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Tooltip title={name === "Visual Studio" ? "Visual Basic" : name}>
        <img
          src={icon}
          alt={name}
          height="30"
          width="30"
          style={{
            filter: "drop-shadow(5px 5px 5px #222)",
            transform: hover && "scale(1.2)",
          }}
        />
      </Tooltip>
    </Box>
  );
}

function WindowsLogo() {
  return (
    <svg
      viewBox="0 0 128 128"
      height="20"
      width="20"
      style={{ marginLeft: "10" }}
    >
      <path
        fill="#fff"
        d="M126 1.637l-67 9.834v49.831l67-.534zM1.647 66.709l.003 42.404 50.791 6.983-.04-49.057zm56.82.68l.094 49.465 67.376 9.509.016-58.863zM1.61 19.297l.047 42.383 50.791-.289-.023-49.016z"
      ></path>
    </svg>
  );
}
