import { Button } from "@mui/material";
import { Link } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";

export default function Main() {
  return (
    <Button
      variant="outlined"
      sx={{ m: 2, position: "absolute", top: 0, left: 0 }}
      startIcon={<ArrowBack />}
      component={Link}
      to="/"
    >
      Home
    </Button>
  );
}
