import React from "react";
import { Helmet } from "react-helmet";
import {
  Typography,
  Stack,
  Divider,
  Paper,
  Fade,
  Grow,
  List,
  ListItem,
  Collapse,
  ListItemIcon,
  ListItemText,
  Grid,
  Box,
} from "@mui/material";

import { useInView } from "react-intersection-observer";

import skillList from "../data/skillList";
import MenuePage from "../common/components/MenuePage";

export default function Main() {
  const [ref, inView] = useInView({
      threshold: 1,
    }),
    [ref1, inView1] = useInView({
      threshold: 0,
    }),
    [ref2, inView2] = useInView({
      threshold: 0,
    }),
    [ref3, inView3] = useInView({
      threshold: 0,
    });

  const [beforeListRef, beforeListInView] = useInView({
      threshold: 0,
    }),
    [listref, listVis] = useInView({
      threshold: 0,
    });

  const [secondary, setSecondary] = React.useState(false),
    [third, setThird] = React.useState(false),
    [fourth, setFourth] = React.useState(false);
  const [showList, setShowList] = React.useState(false),
    [showList2, setShowList2] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setSecondary(true);
    }, 1000);
    setTimeout(() => {
      setShowList(true);
    }, 2000);
    setTimeout(() => {
      setShowList2(true);
    }, 3000);
    setTimeout(() => {
      setThird(true);
    }, 3500);
    setTimeout(() => {
      setFourth(true);
    }, 4500);
  }, []);

  const skillList1 = skillList.slice(0, Math.ceil(skillList.length / 2));
  const skillList2 = skillList.slice(Math.ceil(skillList.length / 2));

  const timeFrame = new Date().getFullYear() - 2018;

  return (
    <MenuePage title="Meet Adam" subtitles={["Fullstack developer"]}>
      <Helmet>
        <title>Adam Desa's Portfolio | About</title>
        <meta name="description" content="About Adam Desa" />
      </Helmet>
      <Box sx={{ width: 0, height: 0 }} ref={ref} />
      <AppearingText
        title="Specialization"
        subText={
          <>
            I specialize in web application development using
            <Box
              sx={{
                display: "inline-block",

                pr: 0.5,
                ml: 0.5,
                borderRadius: "4px",
                py: 0.5,
              }}
            >
              <img
                src={skillList[1].icon}
                alt={skillList[1].name}
                height="20"
                width="20"
                style={{
                  display: "inline-block",
                  margin: "0 5px",
                  verticalAlign: "sub",
                  filter: skillList[1].light
                    ? "drop-shadow(0px 0px 2px rgba(255,255,255,1))"
                    : "drop-shadow(0px 0px 2px rgba(0,0,0,0.6))",
                }}
              />
              React
            </Box>
            for the frontend and
            <Box
              sx={{
                display: "inline-block",
                pr: 0.5,
                ml: 0.5,
                borderRadius: "4px",
                py: 0.5,
              }}
            >
              <img
                src={skillList[3].icon}
                alt={skillList[3].name}
                height="20"
                width="20"
                style={{
                  display: "inline-block",
                  marginLeft: "5px",
                  marginRight: "3px",
                  verticalAlign: "sub",
                  filter: skillList[3].light
                    ? "drop-shadow(0px 0px 2px rgba(255,255,255,1))"
                    : "drop-shadow(0px 0px 2px rgba(0,0,0,0.6))",
                }}
              />
              Flask
            </Box>
            for the backend.
          </>
        }
        show={true}
        inVeiw={inView}
        disableTypography
      />
      <Fade in={secondary} timeout={2000}>
        <Divider sx={{ my: 5 }} />
      </Fade>
      <Collapse in={secondary}>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={4}>
            <Box sx={{ width: 0, height: 0 }} ref={beforeListRef} />
            <AppearingText
              title="Design"
              subText="I strive to create a professional, creative and elegant UI/UX design with exceptional front end development skills."
              show={secondary}
              inVeiw={beforeListInView}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Collapse in={showList}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Grow in={secondary} timeout={2000}>
                  <Typography variant="h4" align="center">
                    Languages and Tools
                  </Typography>
                </Grow>
                <Grow in={secondary} timeout={3000}>
                  <Paper variant="outlined" sx={{ p: 2 }} ref={ref1}>
                    <Stack
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                      direction={{ xs: "column", md: "row" }}
                      divider={
                        skillList2 ? (
                          <Divider orientation="vertical" flexItem />
                        ) : null
                      }
                    >
                      <Collapse in={showList && inView1} timeout={700}>
                        <List>
                          {skillList1.map((skill, index) => (
                            <CustomListItem key={index} item={skill} />
                          ))}
                        </List>
                      </Collapse>
                      <Collapse in={showList2 && inView1} timeout={700}>
                        <List ref={listref}>
                          {skillList2.map((skill, index) => (
                            <CustomListItem key={index} item={skill} />
                          ))}
                        </List>
                      </Collapse>
                    </Stack>
                  </Paper>
                </Grow>
              </Stack>
            </Collapse>
          </Grid>
          <Grid item xs={12} lg={4}>
            <AppearingText
              title="Code"
              subText="Well organised, clean, maintainable and scalable code. I value the ability to learn new technologies and improve my skills."
              show={secondary}
              inVeiw={listVis}
            />
          </Grid>
        </Grid>
      </Collapse>
      <Fade in={third} timeout={2000}>
        <Divider sx={{ my: 5 }} ref={ref2} />
      </Fade>
      <AppearingText
        title="Experience"
        subText={`I have been coding for ${timeFrame} years and have worked on a variety of projects using the above technologies.`}
        show={third}
        inVeiw={inView2}
        AppearRef={ref3}
      />
      <Box sx={{ mb: 5 }} />
      <AppearingText
        title="Other Interests"
        subText="I am passionate about digital art and working
            on my own projects."
        show={fourth}
        inVeiw={inView3}
      />
    </MenuePage>
  );
}

function CustomListItem({ index, item }) {
  return (
    <ListItem>
      <ListItemIcon>
        <img
          src={item.icon}
          alt={item.name}
          height="40"
          width="40"
          style={{
            filter: item.light
              ? "drop-shadow(0px 0px 2px rgba(255,255,255,1))"
              : "drop-shadow(0px 0px 2px rgba(0,0,0,0.6))",
          }}
        />
      </ListItemIcon>
      <ListItemText sx={{ textShadow: "5px 5px 5px #222" }}>
        {item.name}
      </ListItemText>
    </ListItem>
  );
}

function AppearingText({
  title,
  subText,
  show,
  inVeiw,
  children,
  AppearRef,
  disableTypography,
}) {
  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      {AppearRef && <Box sx={{ width: 0, height: 0 }} ref={AppearRef} />}
      <Grow in={show && inVeiw} timeout={2000}>
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      </Grow>
      <Grow in={show && inVeiw} timeout={3000}>
        {disableTypography ? (
          <Box>{subText}</Box>
        ) : (
          <Typography variant="body1" align="center">
            {subText}
          </Typography>
        )}
      </Grow>
      <Collapse in={show && inVeiw} timeout={3000}>
        {children}
      </Collapse>
    </Stack>
  );
}
