import React from "react";
import { Box } from "@mui/system";
import _uniqueId from "lodash/uniqueId";
import { useTheme } from "@emotion/react";
import "./styles/eye.css";

export default function Eye({ color, scale = 5 }) {
  const eyeHeight = 24;
  const eyeWidth = 18;
  const [id] = React.useState(_uniqueId("animatedEye-"));

  const [eyeAngle, setEyeAngle] = React.useState(225);
  const [mousePos, setMousePos] = React.useState({ x: null, y: null });
  const [eyeisCenter, setEyeisCenter] = React.useState(true);

  React.useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePos({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  React.useEffect(() => {
    const eye = document.querySelector(`.${id}`);
    const { left, top, width, height } = eye.getBoundingClientRect();
    const x = mousePos.x - left - width / 2;
    const y = mousePos.y - top - height / 2;
    const angle = Math.atan2(y, x);
    const angleDeg = 45 + (angle * 180) / Math.PI - 90;

    if (50 >= Math.abs(x) && 50 >= Math.abs(y)) {
      setEyeisCenter(true);
    } else {
      if (mousePos.x !== null && mousePos.y !== null) setEyeisCenter(false);
    }
    if (mousePos.x !== null && mousePos.y !== null) setEyeAngle(angleDeg);
  }, [id, mousePos]);

  const theme = useTheme();

  const eyeColour = color || theme.palette.primary.main;

  return (
    <Box
      sx={{
        width: eyeWidth * scale,
        height: (eyeHeight / 2) * scale,
        m: "auto",
      }}
    >
      <div
        className={id}
        style={{
          position: "relative",
          display: "block",
          transform: `scale(${scale})`,
          width: `${eyeHeight}px`,
          height: `${eyeWidth}px`,
          borderBottomRightRadius: "100px",
          borderBottomLeftRadius: "100px",
          overflow: "hidden",
          boxSizing: "border-box",
          margin: "auto",
        }}
      >
        <div
          style={{
            content: " ",
            display: "block",
            borderRadius: "100px",
            position: "absolute",
            boxSizing: "border-box",
            top: "2px",
            boxShadow: `${eyeColour} inset 0 -8px 0 2px,${eyeColour}  inset 0 0 0 2px`,
            width: `${eyeHeight}px`,
            height: `${eyeHeight}px`,
            backgroundColor: "rgba(0,0,0,0.1)",
          }}
        ></div>
        <div
          style={{
            content: " ",
            display: "block",
            position: "absolute",
            boxSizing: "border-box",
            top: "2px",
            width: `${eyeHeight}px`,
            height: `${eyeHeight}px`,
            borderRadius: "100px",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              width: "100%",
              height: 0,
              backgroundColor: eyeColour,
              animation: "blink 30s infinite",
            }}
          ></div>
        </div>
        <div
          style={{
            content: " ",
            display: "block",
            borderRadius: "100px",
            position: "absolute",
            boxSizing: "border-box",
            width: "8px",
            height: "8px",
            bottom: "4px",
            left: "8px",
            transform: `rotate(${eyeAngle}deg)`,
          }}
        >
          <div
            className={`iris${eyeisCenter ? " eyeIsCenter" : ""}`}
            style={{
              content: " ",
              display: "block",
              borderRadius: "100px",
              boxSizing: "border-box",
              width: "8px",
              height: "8px",
              border: `1px solid ${eyeColour} `,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: `translate(-50%, -50%)`,
                width: "3px",
                height: "3px",
                background: `${eyeColour} `,
                borderRadius: "50%",
                content: " ",
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
}
