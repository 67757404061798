import React from "react";
import { Helmet } from "react-helmet";
import { Box, Typography, Stack, Button, Link, Grow } from "@mui/material";
import { GitHub, Instagram, Email } from "@mui/icons-material";
import MenuePage from "../common/components/MenuePage";

import BMCLogo from "../assets/Bmc";

export default function Main() {
  const [animate, setAnimate] = React.useState(false),
    [animate2, setAnimate2] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => setAnimate(true), 500);
    setTimeout(() => setAnimate2(true), 1000);
  }, []);

  return (
    <MenuePage
      title="Let's Chat!"
      subtitles={["Have a project, idea or problem you would like to discuss?"]}
    >
      <Helmet>
        <title>Adam Desa's Portfolio | Contact Me</title>
        <meta name="description" content="Need to contact me, here's how" />
      </Helmet>
      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grow in={animate} timeout={500}>
          <Box>
            <Typography variant="h4" align="center" gutterBottom>
              My socials
            </Typography>
            <Stack direction="row" justifyContent="center">
              <Stack
                spacing={2}
                justifyContent="center"
                alignItems="stretch"
                direction="column"
                sx={{ mb: 2, maxWidth: "max-content" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Email />}
                  component={Link}
                  href="mailto: contact@adamdesa.com"
                >
                  contact@adamdesa.com
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<GitHub />}
                  component={Link}
                  href="https://github.com/Skulldorom"
                  target="_blank"
                >
                  GitHub
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Instagram />}
                  component={Link}
                  href="https://www.instagram.com/skulldorom/"
                  target="_blank"
                >
                  Instagram
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Grow>
        <Grow in={animate2} timeout={500}>
          <Box sx={{ mt: 5 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ mt: 2 }}>
              Coffee?
            </Typography>
            <Typography variant="body1" align="center" gutterBottom>
              If you feel like supporting me
            </Typography>
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
              direction="column"
              sx={{ mb: 2 }}
            >
              <Button
                variant="contained"
                color="primary"
                component={Link}
                href="https://www.buymeacoffee.com/Skulldorom"
                target="_blank"
                sx={{ p: 1.5 }}
              >
                <BMCLogo height="45" width="100%" />
              </Button>
            </Stack>
          </Box>
        </Grow>
      </Box>
    </MenuePage>
  );
}
